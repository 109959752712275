
#root {min-height: 500px;}
body {
  font-family: 'DM Sans';
}

@media all {
  .navbar {
    width: 100% !important;
    min-width: 100% !important;
  }
  html, body {
    min-width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
}
.badge-like {
  color: #22534C;
  cursor: pointer;
}
.badge-like a {
  color: #22534C;
  cursor: pointer;
}
.active .nav-link {
  color: #22534C !important;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.red {color: #f36161;}
.navbar-brand i {color: #22534C; font-size: 26px; margin-right: 10px;}
.navbar-brand span {color: #22534C; font-weight: 600;}
.li-logout i { margin-right: 5px;}
ul {list-style: none; padding-left: 5px;}
li {list-style: none;}
.post i {cursor: pointer;}
hr {color: #d3d3d3; margin: 3px 0;}
.post .post-url {font-size: 12px; color: #62636B;}
.post .post-date {font-size: 12px; color: #62636B;}
.post .post-username {font-size: 12px; color: #62636B;}
.post .post-username a {font-size: 12px; color: #62636B;}
.post .btn-vote {font-size: 22px; color: #22534C;}

.comment { padding: 10px; border-left: 1px solid #EBEBEC; border-bottom: 1px solid #EBEBEC;}
.comment-footer {font-size: 12px; color: #62636B;}
.comment-footer .btn-reply {cursor: pointer; text-decoration: underline;}
.user-info {padding-top: 30px; border-radius: 10px;}
small {color: #62636B;}

.page {color: #62636B; cursor: pointer; font-size: 14px;}
.page .active {color: #22534C;}
.page:hover span {color: #22534C;}

.green {color: #22534C;}
.bg-green {color: #22534C;}

.playfair {
  font-family: 'Playfair Display';
  color: #22534C;
}
.playfair-heading {
  font-family: 'Playfair Display';
  color: #353640;
  font-size: 24px;
  font-weight: 900;
}
.playfair-heading-700 {
  font-family: 'Playfair Display';
  color: #353640;
  font-size: 48px;
  font-weight: 700;
}
.w-60 {
  width: 60%;
}
@media screen and (min-width: 375px) {
  .w-60 {
    width: 100%;
  }
}
@media screen and (min-width: 500px) {
  .w-60 {
    width: 60%;
  }
}
.dot {
  font-size: 4px;
  color: #62636B;
  margin: 0px 8px;
}
.dm-sans {
  font-family: 'DM Sans';
}
.btn-green {
  background-color: #22534C;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.btn-green:hover {
  background-color: #1b443e;
  border: none;
  box-shadow: none;
}
.btn-green:focus {
  background-color: #22534C;
  border: none;
  box-shadow: none;
}
.btn-green:active {
  background-color: #22534C;
  border: none;
  box-shadow: none;
}
.nav-link.active {
  color: #22534C!important;
  font-weight: 700;
}
.loading {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.4);
  z-index: 1000;
}

.loading-icon {
  color: black;
  position: fixed;
  top: 40%;
  left: 47%;
  width: 100px;
  height: 100px;
}